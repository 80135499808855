/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import DefaultBanner from "examples/Banner/DefaultBanner";
// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/artform-banner.png";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      />
      <DefaultBanner></DefaultBanner>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            mx: "auto",
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <MKTypography
            variant="h2"
            color="black"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Corporate
          </MKTypography>
          <MKTypography
            variant="body1"
            color="black"
            opacity={0.8}
            mt={1}
            mb={3}
          >
            We as a Artform Thermoform team We have nearly 15 years experience
            and know-how make in the design and manufacture of thermoforming
            tools for highest precision and quality Artform Thermoform aims to
            manufacturing highest level quality thermoforming moulds and become
            one of the most well known thermoforming mould maker in worldwide.
            We design products provided by the client or developed by our
            designers.Artform Thermoform manufactures the best thermoforming
            moulds suitable for processing materials such as PP, PS, PET, OPS
            and other plastics with the well qualified and experienced stuff,
            advanced Cad/Cam software.
          </MKTypography>
        </Grid>
      </Container>
      <MKBox pt={0} px={1} mt={0}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
