/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultBanner from "examples/Banner/DefaultBanner";
import CenteredFooter from "examples/Footers/CenteredFooter";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

// Images
import image1 from "../../assets/images/artform-images/artform-image-1.jpeg";
import image2 from "../../assets/images/artform-images/artform-image-2.jpeg";
import image3 from "../../assets/images/artform-images/artform-image-3.jpeg";
import image4 from "../../assets/images/artform-images/artform-image-4.jpeg";
import image5 from "../../assets/images/artform-images/artform-image-5.jpeg";
import image6 from "../../assets/images/artform-images/artform-image-6.jpeg";
import image7 from "../../assets/images/artform-images/artform-image-7.jpeg";
import image8 from "../../assets/images/artform-images/artform-image-8.jpeg";
import image9 from "../../assets/images/artform-images/artform-image-9.jpeg";
import image10 from "../../assets/images/artform-images/artform-image-10.jpeg";
import image11 from "../../assets/images/artform-images/artform-image-11.jpeg";
import image12 from "../../assets/images/artform-images/artform-image-12.jpeg";
import image13 from "../../assets/images/artform-images/artform-image-13.jpeg";
import image14 from "../../assets/images/artform-images/artform-image-14.jpeg";
import image15 from "../../assets/images/artform-images/artform-image-15.jpeg";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      />
      <DefaultBanner></DefaultBanner>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            mx: "auto",
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <MKTypography
            variant="h2"
            color="black"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Corporate
          </MKTypography>
          <MKTypography
            variant="body1"
            color="black"
            opacity={0.8}
            mt={1}
            mb={3}
          >
            We as a Artform Thermoform team We have nearly 15 years experience
            and know-how make in the design and manufacture of thermoforming
            tools for highest precision and quality Artform Thermoform aims to
            manufacturing highest level quality thermoforming moulds and become
            one of the most well known thermoforming mould maker in worldwide.
          </MKTypography>
          {
            <MKButton
              color="default"
              sx={{ color: ({ palette: { light } }) => light.main }}
            >
              <MKTypography
                component={Link}
                to="/about"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {" "}
                Detail
              </MKTypography>
            </MKButton>
          }
        </Grid>
      </Container>
      <Container sx={{
            marginBottom: "50px",
          }} >
        <Grid item xs={12} lg={12}>
          {" "}
          <Grid container>
            <iframe
              width="100%"
              height="481px"
              src="https://www.youtube.com/embed/5NhQwTNgNRk?control=0&autoplay=1"
              title="500 CC SALATA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MKBox
                component="img"
                src={image5}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MKBox
                component="img"
                src={image6}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MKBox
                component="img"
                src={image7}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image1}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image2}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image3}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image4}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image8}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image9}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image10}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image11}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image12}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image13}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image14}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MKBox
                component="img"
                src={image15}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <MKBox pt={0} px={1} mt={0}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
