/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import bgImage from "assets/images/artform-banner.png";
import bgImageMobile from "assets/images/artform-banner-mobile.png";


function DefaultBanner() {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
        console.log(window.innerWidth)
        console.log(breakpoints.values.lg)
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
      console.log(mobileView)
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <MKBox
    height="75vh"
    width="auto"
    sx={{
       backgroundImage: `url(${mobileView == true ? bgImageMobile : bgImage })`,
       backgroundSize: "cover",
       backgroundPosition: "top center",
       backgroundRepeat: "no-repeat",
       display: "grid",
       placeItems: "center",
     }}
   >
     <Container>
       <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
         <MKTypography
           variant="h1"
           color="white"
           mt={-6}
           mb={1}
           sx={({ breakpoints, typography: { size } }) => ({
             [breakpoints.down("md")]: {
               fontSize: size["3xl"],
             },
           })}
         >
           {/* ArtFormThermoform {" "} */}
         </MKTypography>
         <MKTypography
           variant="body1"
           color="white"
           textAlign="center"
           px={{ xs: 6, lg: 12 }}
           mt={1}
         >
           {/* Thermoforming Machine & Injection Plastic Mold is our passion. You can see it in our works that are presented here. We ship from Istanbul to the whole world */}
         </MKTypography>
       </Grid>
     </Container>
   </MKBox>
  );
}


export default DefaultBanner;
